import TrackVisibility from 'react-on-screen'
import CountUp from 'react-countup'
import 'animate.css'

export const Services = () => {
  return (
    <section className="services" id="services" name="services">
      <div className='top-services'></div>
      <div className="parallax"></div>
      <div className="parallax-content">
        <div className="box-left">
          <TrackVisibility>
            {({ isVisible }) =>
              <>
                <h3 className={isVisible ? 'animate__animated animate__slideInDown' : ''}>IT</h3>
                <p className={isVisible ? 'animate__animated animate__slideInDown' : ''}>
                  Mobile apps and software translations and proofreading
                </p>
                <p className={isVisible ? 'animate__animated animate__slideInDown' : ''}>
                  I provide flawless, technically accurate text that strikes a pitch-perfect tone in Czech.<br />
                  Whether you need to translate a product description, UI, software guide or technical manual, you’re in safe hands.
                </p>
              </>
            }
          </TrackVisibility>

        </div>
        <div className='triangle-left'></div>
        <div className='next-row'></div>
        <div className='break'></div>
        <div className='triangle-right'></div>
        <div className="box-right">
          <TrackVisibility>
            {({ isVisible }) =>
              <>
                <h3 className={isVisible ? 'animate__animated animate__slideInDown' : ''}>Financial</h3>
                <p className={isVisible ? 'animate__animated animate__slideInDown' : ''}>Financial translations and proofreading</p>
                <p className={isVisible ? 'animate__animated animate__slideInDown' : ''}>I have personal experience, interest and up-to-date knowledge in investing, trading, Forex, cryptocurrency, stocks and I can provide a perfect translation in these domains.</p>
              </>
            }
          </TrackVisibility>
        </div>
        <div className='next-row'></div>
        <div className="box-left">
          <TrackVisibility>
            {({ isVisible }) =>
              <>
                <h3 className={isVisible ? 'animate__animated animate__slideInDown' : ''}>Marketing</h3>
                <p className={isVisible ? 'animate__animated animate__slideInDown' : ''}>Marketing translations and proofreading</p>
                <p className={isVisible ? 'animate__animated animate__slideInDown' : ''}>Promotional e-mails, marketing texts, articles, subtitles for videos, websites, product campaigns, B2B catalogues, or anything else marketing-related I can translate with accuracy and professionalism.</p>
              </>
            }
          </TrackVisibility>
        </div>
        <div className='triangle-left'></div>
      </div>
      <div className='bottom-services'>
        <TrackVisibility>
        {({isVisible}) =>
        <>
        {isVisible &&
        <>
        <div className='box'>
          <div className='counter'>
            <CountUp end={13} duration={1} />
          </div>
          <h3>Years of experience</h3>
        </div>
        <div className='box'>
          <div className='counter'>
            <CountUp end={110} duration={1} />
          </div>
          <h3>Satisfied Clients</h3>
        </div>
        <div className='box'>
          <div className='counter'>
            <CountUp end={25} duration={1} />
          </div>
          <h3>Positive reviews</h3>
        </div>
        <div className='box'>
          <div className='counter'>
            <CountUp end={4.83} decimals={2} duration={1} />
          </div>
          <h3>Average score</h3>
        </div>
        </>
        }
        </>
        }
        </TrackVisibility>
      </div>
    </section>
  )
}