import { useState, useEffect } from "react";

export const Header = () => {
  const [activeLink, setActiveLink] = useState('home')
  const [scrolled, setScrolled] = useState(false)
  const [menuActive, setMenuActive] = useState(false)

  useEffect(() => {
    const onScroll = () => {

      if (window.scrollY > 50) {
        setScrolled(true);
      } else {
        setScrolled(false)
      }

    }

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [])

  const onUpdateActiveLink = (value) => {
    setActiveLink(value);
    setMenuActive(false);
  }

  const toggleMenuClass = () => {
    setMenuActive(!menuActive)
  }
  return (
    <header className={scrolled ? "header scrolled" : "header"}>
      <a href="#home" className="logo" onClick={() => onUpdateActiveLink('home')}>Petr Hrabe</a>
      <i id="menu-icon" className={menuActive ? 'bx bx-x' : 'bx bx-menu'} onClick={toggleMenuClass}></i>

      <nav className={menuActive ? 'navbar active' : 'navbar'} >
        <a href='#home' className={activeLink === 'home' ? 'active' : ''} onClick={() => onUpdateActiveLink('home')}>Home</a>
        <a href='#about' className={activeLink === 'about' ? 'active' : ''} onClick={() => onUpdateActiveLink('about')}>About</a>
        <a href='#services' className={activeLink === 'services' ? 'active' : ''} onClick={() => onUpdateActiveLink('services')}>Services</a>
        <a href='#pricing' className={activeLink === 'pricing' ? 'active' : ''} onClick={() => onUpdateActiveLink('pricing')}>Pricing</a>
        <a href='#testimonials' className={activeLink === 'testimonials' ? 'active' : ''} onClick={() => onUpdateActiveLink('testimonials')}>Testimonials</a>
        <a href='#contact' className={activeLink === 'contact' ? 'active' : ''} onClick={() => onUpdateActiveLink('contact')}>Contact</a>
      </nav>

    </header>
  )
}