const getAge = (sinceDate) => {
  const then = new Date(sinceDate)
  const month_diff = Date.now() - then.getTime()
  const age_dt = new Date(month_diff)
  const year = age_dt.getUTCFullYear()
  const age = Math.abs(year - 1970)
  return age
}

export const About = () => {

  return (
    <section className='about' id='about' name='about'>
      <div className="about-content">
        <h2>Specialist Translator from English to Czech</h2>
        <p>Hi, I’m a freelance English-to-Czech translator specialising in IT, finance and marketing.</p>
        <h3>Why choose me</h3>
        <ul>
          <li>{getAge('2011-01-01')} years experience as a professional translator</li>
          <li>Native Czech speaker with fluent English (highests-level Cambridge certificate)</li>
          <li>Over 110 satisfied clients in diverse fields</li>
          <li>Lived and worked in the UK for 8 years. I'm practically native at this stage.</li>
          <li>Friendly, flexible and fast service</li>
        </ul><br />
        <h3>Who am I</h3>
        <p>Before becoming a full-time translator, I worked for many years as a programmer, internet marketer and pay-per-click specialist.</p>
        <p>I also ran my own IT solutions business in Manchester, UK where I lived for 8 years.</p>
        <p>That means I have in-depth knowledge and expertise in these fields. You can be sure your document will be precise, natural and perfectly suited to a Czech context.</p>
        <p>Before becoming a translator, I specialised as an internet marketer helping companies boost lead generation by developing and implementing SEO-optimised strategies. As a PPC specialist, I helped my clients increase ROI with custom campaigns, working on a wide range of projects from small websites to large e-commerce platforms.</p>
        <h3>Aside from my specialty areas, do I translate any other English documents into Czech?</h3>
        <p>Yes, I do. If you need a <span>medical</span> or <span>legal</span> translation, I’ve done plenty. And if you need a <span>personal document</span> in English that needs to be translated into Czech, I’ll provide you with a natural-sounding result.</p>
        <h3>What if I have a translated text in Czech that’s not quite right?</h3>
        <p>I can help with that, too. I provide a Czech proofreading service in all of the above fields.</p>
      </div>
    </section>
  )
}