

export const Footer = () => {
  return (
    <footer className="footer">
      <div className="c-socials">
        <a href="https://www.proz.com/profile/2285693" target="_blank" rel="noreferrer"><img src="images/proz.png" alt="" /></a>
        <a href="https://www.linkedin.com/in/petrhrabe/" target="_blank" rel="noreferrer"><img src="images/linkedin.png" alt="" /></a>
      </div>
      <div className="footer-text">
        <p>Copyright &copy; 2024 Petr Hrabe</p>
      </div>
    </footer>
  )
}