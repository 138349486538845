import { useState } from "react";
import 'animate.css';

export const Contact = () => {

  const mailServerUrl = process.env.REACT_APP_MAILSERVER
  const formInitialDetails = {
    appMailUser: process.env.REACT_APP_MAILUSER,
    appMailPass: process.env.REACT_APP_MAILPASS,
    defaultTo: process.env.REACT_APP_MAILTO,
    website: 'petrhrabe.com',
    name: '',
    email: '',
    fileType: '',
    wordCount: '',
    preferredDeadline: '',
    message: ''
  }
  const [formDetails, setFormDetails] = useState(formInitialDetails);
  const [buttonText, setButtonText] = useState('Send message');
  const [status, setStatus] = useState({});

  const onFormUpdate = (category, value) => {
    setFormDetails({
      ...formDetails,
      [category]: value
    })
  }

  // console.log('reactapp_mail_user: ' + formInitialDetails.appMailUser)
  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonText("Sending...");
    let response = await fetch(`${mailServerUrl}/contact`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8",
      },
      body: JSON.stringify(formDetails),
    });
    setButtonText("Send message");
    let result = await response.json();
    setFormDetails(formInitialDetails);
    if (result.code === 200) {
      setStatus({ succes: true, message: 'Message sent successfully' });
    } else {
      setStatus({ succes: false, message: 'Something went wrong, please try again later.' });
    }
  };

  return (
    <section className="contact" id="contact">
      <div>
        <h3>Contact Me</h3>
        <form onSubmit={handleSubmit}>
          <div className="input-box">
            <input type="text" value={formDetails.name} placeholder="Name" onChange={(e) => onFormUpdate('name', e.target.value)} />
            <input type="email" value={formDetails.email} placeholder="Email" onChange={(e) => onFormUpdate('email', e.target.value)} />
          </div>
          <div className="input-box" >
            <input type="text" value={formDetails.fileType} placeholder="File type" onChange={(e) => onFormUpdate('fileType', e.target.value)} />
            <input type="text" value={formDetails.wordCount} placeholder="Word Count (if possible)" onChange={(e) => onFormUpdate('wordCount', e.target.value)} />
            <input type="text" value={formDetails.preferredDeadline} placeholder="Preferred deadline" onChange={(e) => onFormUpdate('preferredDeadline', e.target.value)} />
          </div>
          <textarea cols="30" rows="6" value={formDetails.message} placeholder="Message" onChange={(e) => onFormUpdate('message', e.target.value)}></textarea>
          <button type="submit" className="btn">{buttonText}</button>
          {
            status.message &&
            <p className={status.success === false ? "danger" : "success"}>{status.message}</p>
          }
        </form>
      </div>
    </section>
  )
}