

export const Testimonials = () => {
  return (
    <section className="testimonials" id="testimonials" name="testimonials">
      <h3>Testimonials</h3>
      <p>
        My verified reviews are on <a href="https://www.proz.com/feedback-card/2285693" target="_blank" rel="noopener noreferrer">ProZ.com</a>, a trusted resource for language professionals worldwide. What some of my clients said:
      </p>
      <div className="t-boxes">
        <div className="t-box">
          <div>
            <p>Petr was professional, communicated in a timely manner, and delivered within agreed deadline. Would definitely consider engaging him in similar projects in the future.</p>
          </div>
          <div className="signature">
            <h4>European Association of Urology</h4>
          </div>
        </div>
        <div className="t-box">
          <div>
            <p>Petr is a good translator in English to Czech language pair. Besides reasonable pricing, he is very professional and committed to delivery always.</p>
          </div>
          <div className="signature">
            <h4>GEM Translators</h4>
          </div>
        </div>
        <div className="t-box">
          <div>
            <p>It's a pleasure to work with Petr. He is responsive and always committed to the deadline. The delivery quality is good. Highly recommended. 😊</p>
          </div>
          <div className="signature">
            <h4>Clearly Local</h4>
          </div>
        </div>
      </div>
    </section>
    )
}