import { TypeAnimation } from 'react-type-animation';
import 'animate.css';

export const Home = () => {

  return (
    <section className='home' id='home' name='home'>
      <div className="home-content">
        <div className='home-content-text'>
          <h1>Petr Hrabe</h1>
          <h3>
            <TypeAnimation
              style={{ whiteSpace: 'pre-line', height: '25%', display: 'block' }}
              sequence={[
                "Are you looking for a professional translation from English to Czech Language?", 1000,
                "Are you looking for a professional translation from English to Czech Language? \n\nIs your document in the field of IT, Finance or Marketing?", 0,
              ]}
              speed={70}
              repeat={0}
            />
          </h3>
          <h1>
            <TypeAnimation
              sequence={[
                7000,
                "If your answer is yes, you're in the right place!", 0,
              ]}
              speed={70}
              repeat={0}
              cursor={false}
              style={{ color: '#fff' }}
            />
          </h1>
        </div>
      </div>
      <div className="home-img">
        <img className="animate__animated animate__fadeIn" src='/images/petr01.png' alt='me' />
      </div>
    </section>
  )
}
