

export const Pricing = () => {
    return (
        <>
            <section className="pricing" id="pricing" name="pricing">
                <h3>Pricing</h3>
                <p>
                I'm happy to provide a FREE quote for your project. 
				The more info you can give me the better. I do my best to make sure my rates are competitive and fair.
                </p>
            </section>
        </>
    )
}